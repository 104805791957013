<template>
  <EntityList
    ref="videoList"
    :config="config"
    :columns="columns"
    :filter-vals.sync="filterVals"
    :filters="filters"
    :extra-validation="extraValidation"
    :formatted-columns="formattedColumns"
    authority="Congress_Management_Video"
    :form-fields="formFields"
  />
</template>

<script>
import EntityList from '@/layouts/entity/EntityList.vue'
import storeModule from './videoStoreModule'

export default {
  components: {
    EntityList,
  },
  data() {
    return {
      filterVals: [],
      visibilityOptions: [
        { id: 1, title: 'Public' },
        { id: 2, title: 'Unlisted' },
        { id: 3, title: 'Hidden' },
      ],
      congressOptions: [],
      workingGroupOptions: [],
      speakerOptions: [],
    }
  },
  computed: {
    config() {
      return {
        store: storeModule,
        module: 'videos',
        endpoint: 'videos',
        route: 'videos',
        title: {
          single: this.$t('Video Add'),
          plural: this.$t('Videos'),
        },
      }
    },

    columns() {
      return [
        { key: 'id', label: this.$t('id'), sortable: true },
        { key: 'title', label: this.$t('Title'), sortable: true },
        { key: 'vimeoId', label: this.$t('Vimeo ID'), sortable: false },
        { key: 'congress.title', label: this.$t('Congress'), sortable: false },
        { key: 'workingGroup.title', label: this.$t('Working Group'), sortable: false },
        { key: 'speaker.title', label: this.$t('Speaker'), sortable: false },
        { key: 'room.title', label: this.$t('Room'), sortable: false },
        { key: 'startDate', label: this.$t('Date'), sortable: true },
        // { key: 'creationDate', label: this.$t('Creation Date'), sortable: true },
        // { key: 'isPrivate', label: this.$t('Private'), sortable: false },
        { key: 'unlisted', label: this.$t('Unlisted'), sortable: false },
        { key: 'actions', label: this.$t('actions') },
      ]
    },
    formattedColumns() {
      return [
        // { key: 'isPrivate', type: 'boolean' },
        { key: 'unlisted', type: 'boolean' },
        { key: 'startDate', type: 'date' },
      ]
    },
    filters() {
      return [
        {
          name: 'congress',
          label: this.$t('Congress'),
          options: this.congressOptions,
        },
        {
          name: 'workinggroup',
          label: this.$t('Working Group'),
          options: this.workingGroupOptions,
        },
        {
          name: 'speaker',
          label: this.$t('Speaker'),
          options: this.speakerOptions,
        },
        {
          name: 'datefrom',
          type: 'date',
          label: this.$i18n.t('Date From'),
          maxDateFieldIdx: '4',
          maxDate: new Date(),
        },
        {
          name: 'dateto',
          type: 'date',
          label: this.$i18n.t('Date To'),
          minDateFieldIdx: '3',
          maxDate: new Date(),
        },
        {
          name: 'createdfrom',
          type: 'date',
          label: this.$i18n.t('Created From'),
          maxDateFieldIdx: '6',
          maxDate: new Date(),
        },
        {
          name: 'createdto',
          type: 'date',
          label: this.$i18n.t('Created To'),
          minDateFieldIdx: '5',
          maxDate: new Date(),
        },
      ]
    },
    // extraActions() {
    //   return [
    //     {
    //       // eslint-disable-next-line prefer-template
    //       href: 'https://vimeo.com/',
    //       appendField: 'vimeoId',
    //       icon: 'EyeIcon',
    //       title: 'View in Vimeo',
    //     },
    //   ]
    // },
    formFields() {
      return [
        {
          id: 'congress',
          object: true,
          type: 'select',
          label: this.$t('Congress'),
          options: this.congressOptions,
        },
        {
          id: 'workingGroup',
          object: true,
          type: 'select',
          label: this.$t('Working Group'),
          options: this.workingGroupOptions,
          colSize: 6,
        },
        {
          id: 'speaker',
          object: true,
          type: 'select',
          label: this.$t('Speaker'),
          options: this.speakerOptions,
          colSize: 6,
        },
        {
          id: 'title',
          type: 'text',
          label: this.$t('Title'),
          required: true,
        },
        {
          id: 'ordering',
          type: 'text',
          label: this.$t('ordering'),
          required: true,
          rules: 'integer',
          colSize: 3,
          initVal: 1,
        },
        {
          id: 'visibility',
          type: 'select',
          label: this.$t('Visibility'),
          options: this.visibilityOptions,
          required: true,
          initVal: 2,
          colSize: 9,
        },
        {
          id: 'video',
          type: 'video',
          label: this.$t('Video'),
          callback: this.refetchData,
        },
        {
          id: 'tags',
          type: 'selectmultiple',
          label: this.$t('Tags'),
          options: [],
          taggable: true,
          pushTags: true,
          colSize: 12,
        },
        {
          id: 'description',
          type: 'quill',
          label: this.$t('Description'),
        },
      ]
    },
  },
  mounted() {
    this.$store.dispatch('app-common/fetchCongresses').then(response => {
      this.congressOptions = response.data
    })
    this.$store.dispatch('app-common/fetchWorkingGroups').then(response => {
      this.workingGroupOptions = response.data
    })
    this.$store.dispatch('app-common/fetchParticipants').then(response => {
      this.speakerOptions = response.data
    })
  },
  methods: {
    extraValidation(entity) {
      if (!entity.vimeoId) {
        return this.$t('Please wait until video upload is completed!')
      }
      return ''
    },
    refetchData() {
      this.$refs.videoList.handleEntitySave()
    },
  },
}
</script>
